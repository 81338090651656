<template>
<temp-default class="bl_index">
    <div class="bl_confirm">
        <p>下記の使用許諾条件を確認し同意の上、「使用許諾条件に同意する」ボタンをクリックして下さい。</p>
        <ol class="bl_confirm_text">
            <li>本プログラムの利用者（以下単に「利用者」といいます。）は、本プログラムを無料で使用することができます。ただし、その際の通信料等は利用者が負担するものとします。</li>
            <li>利用者等が本プログラムの全部又は一部を修正、改変すること、及びリバースエンジニアリング、逆コンパイル又は逆アセンブル等により解析することを禁止します。</li>
            <li>利用者等が本プログラムの全部又は一部を通信回線等により頒布すること、媒体の如何を問わず複製し第三者に譲渡、販売、貸与、使用許諾することを禁止します。</li>
            <li>国土交通省住宅局、国土交通省国土技術政策総合研究所（以下「国総研」といいます。）、国立研究開発法人建築研究所（以下「建研」といいます。）及び本ウェブサイトを管理している（一社）日本サステナブル建築協会（以下「プログラム開発元等」と総称します。）は、理由の如何を問わず、利用者に事前の通知等をすることなく、本プログラムの全部又は一部を変更、追加、削除等（本プログラムの提供の一時的な停止を含みます。以下同じ。）することができるものとします。</li>
            <li>プログラム開発元等は、本プログラムについて、その完全性、正確性、確実性その他いかなる事項に関する保証も行わないものとします。</li>
            <li>プログラム開発元等は、利用者が本プログラムを使用したことに伴っていかなる損害、損失等が生じたとしても、これらについて一切の保証責任及び賠償責任を負わないものとします。</li>
            <li>プログラム開発元等は、第４項により本プログラムの全部又は一部が変更、追加、削除等されたことに伴っていかなる損害、損失等が生じたとしても、これらについて一切の保証責任及び賠償責任を負わないものとします。</li>
            <li>プログラム開発元等は、本プログラムを本サービスで明記する注意制限事項等で特定する以外の機器又はプログラムと組み合わせること、また、第２項に反して本プログラムを改変すること等に起因して生じた損害、損失等について一切の責任を負わないものとします。</li>
            <li>本プログラムを使用する際に入力する建築材料及び建築設備の仕様等や本プログラムにより出力される計算結果及び評価結果に関する情報は、次の①から①までに掲げる目的で、個人や個別の建築物が特定されないようにした上で収集・管理・利用されます。
                <ol class="ol_in_ol">
                    <li>国土交通省住宅局及び所管行政庁等における建築物の省エネ性能の向上に関する施策の検討</li>
                    <li>国総研及び建研等における建築物の省エネ性能に関する調査、試験、研究及び開発</li>
                    <li>所管行政庁及び建築物のエネルギー消費性能の向上に関する法律（平成27年法律第53号）第15条第１項に規定する登録建築物エネルギー消費性能判定機関等における建築物の省エネ性能に関する審査</li>
                </ol>
            </li>
        </ol>
    </div>
    <app-button
        name="入力する"
        @click="toForm"
        class="btn_start"
    />
</temp-default>
</template>

<script>
import AppButton from '@/components/atoms/AppButton.vue'
import TempDefault from '@/components/templates/TempDefault.vue'

export default {
    name: 'Index',
    components: {
        AppButton,
        TempDefault
    },
    data(){
        return {
        }
    },
    methods: {
        toForm(){
            this.$router.push('/calculator')
        }
    }
}
</script>

<style scoped>
.bl_confirm{
    border: 1px solid #53BFD8;
    max-height: calc(100vh - 200px);
    margin: 0 4vw 2rem;
    padding:  1rem 4vw;
    font-size: 14px;
    text-align: justify;
    overflow-y: scroll;
}
.bl_confirm .bl_confirm_text{
    padding-inline-start: 20px;
}
.btn_start{
    display: block;
    width: 200px;
    height: 50px;
    margin-top: -20px;
    margin: 0 auto;
    font-size: 24px;
    letter-spacing: 0.2em;
    background-color: #53BFD8;
}
</style>